import Visite from '@/models/visites/Visite'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Environnement from '@/models/visites/Environnement'
import Document from '@/models/visites/Documents'


/**
* @Name VisitesApiClientUrlsInterface
* @description
* Interface for the Visites urls used to avoid hard-coded strings
*/
export interface VisitesApiClientUrlsInterface {
    getVisites: string
    getVisite: string
    postVisite: string
    putVisite: string
    deleteVisite: string
    transformToNF: string

    generatePDF: string
    generateCertiRenovPDF: string
    getImages: string
    getFiles: string

    getEnvironnements: string
    getEnvironnement: string
    postEnvironnement: string
    putEnvironnement: string
    deleteEnvironnement: string

    getDocument: string
    getDocuments: string
    postDocuments: string
    putDocuments: string
    deleteDocuments: string
}

/**
* @Name VisitesApiClientInterface
* @description
* Interface for the Visites api client module
*/
export interface VisitesApiClientInterface {
    getVisites: (apiFilters?: any) => Promise<Visite[]>
    getVisite: (visite: Visite, apiFilters?: any) => Promise<Visite>
    sendVisite: (visite: Visite) => Promise<Visite>
    deleteVisite: (visite: Visite) => Promise<Visite>
    transformToNF: (visite: Visite) => Promise<Visite>

    generatePDF: (visite: Visite, isCertiRenov: boolean) => Promise<string>
    getImages: (visite: Visite) => Promise<any>
    getFiles: (visite: Visite) => Promise<any>

    getEnvironnements: (apiFilters?: any) => Promise<Environnement[]>
    getEnvironnement: (visite: Environnement, apiFilters?: any) => Promise<Environnement>
    sendEnvironnement: (visite: Environnement) => Promise<Environnement>
    deleteEnvironnement: (visite: Environnement) => Promise<Environnement>

    getDocuments: (apiFilters?: any) => Promise<Document[]>
    getDocument: (visite: Document, apiFilters?: any) => Promise<Document>
    sendDocument: (visite: Document) => Promise<Document>
    deleteDocument: (visite: Document) => Promise<Document>
}

/**
* @Name VisitesApiClientModel
* @description
* Implements the VisitesApiClientInterface interface
*/
export class VisitesApiClientModel implements VisitesApiClientInterface {
    private readonly urls!: VisitesApiClientUrlsInterface
    constructor(urls: VisitesApiClientUrlsInterface) {
        this.urls = urls
    }

    getVisites(apiFilters?: any): Promise<Visite[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisites,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Visite[]>(getParameters)
    }

    getVisite(visite: Visite, apiFilters?: any): Promise<Visite> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisite.replace('$(visiteId)', visite.id!),
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Visite>(getParameters)
    }

    sendVisite(_visite: Visite): Promise<Visite> {
        const visite = Object.assign({}, _visite);
        if (visite.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putVisite.replace('$(visiteId)', visite.id!),
                requiresToken: false,
                payload: visite,
            }
            return HttpClient.put<Visite>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postVisite.replace('$(visiteId)', visite.id!),
                requiresToken: false,
                payload: visite,
            }
            return HttpClient.post<Visite>(getParameters)
        }
    }

    deleteVisite(visite: Visite): Promise<Visite> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteVisite.replace('$(visiteId)', visite.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Visite>(getParameters)
    }

    transformToNF(visite: Visite): Promise<Visite> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.transformToNF.replace('$(visiteId)', visite.id!),
            requiresToken: false,
        }
        return HttpClient.post<Visite>(getParameters)
    }

    generatePDF(visite: Visite, isCertiRenov: boolean): Promise<string> {
        if(isCertiRenov){
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.generateCertiRenovPDF.replace('$(visiteId)', visite.id!),
                requiresToken: false,
                onDownloadProgress: true
            }
            return HttpClient.get<string>(getParameters)
        }else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.generatePDF.replace('$(visiteId)', visite.id!),
                requiresToken: false,
                onDownloadProgress: true
            }
            return HttpClient.get<string>(getParameters)
        }
        
    }

    getImages(visite: Visite): Promise<any> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getImages.replace('$(visiteId)', visite.id!),
            requiresToken: false,
            onDownloadProgress: true
        }
        return HttpClient.get<any>(getParameters)
    }


    getFiles(visite: Visite): Promise<any> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getFiles.replace('$(visiteId)', visite.id!),
            requiresToken: false,
            onDownloadProgress: true
        }
        return HttpClient.get<any>(getParameters)
    }

    getEnvironnements(apiFilters?: any): Promise<Environnement[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getEnvironnements,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Environnement[]>(getParameters)
    }

    getEnvironnement(environnement: Environnement, apiFilters?: any): Promise<Environnement> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getEnvironnement.replace('$(environnementId)', environnement.id!),
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Environnement>(getParameters)
    }

    sendEnvironnement(_environnement: Environnement): Promise<Environnement> {
        const environnement = Object.assign({}, _environnement);
        if (environnement.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putEnvironnement.replace('$(environnementId)', environnement.id!),
                requiresToken: false,
                payload: environnement,
            }
            return HttpClient.put<Environnement>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postEnvironnement.replace('$(environnementId)', environnement.id!),
                requiresToken: false,
                payload: environnement,
            }
            return HttpClient.post<Environnement>(getParameters)
        }
    }

    deleteEnvironnement(environnement: Environnement): Promise<Environnement> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteEnvironnement.replace('$(environnementId)', environnement.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Environnement>(getParameters)
    }
    
    getDocuments(apiFilters?: any): Promise<Document[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getDocuments,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Document[]>(getParameters)
    }

    getDocument(document: Document, apiFilters?: any): Promise<Document> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getDocument.replace('$(documentId)', document.id!),
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Document>(getParameters)
    }

    sendDocument(_document: Document): Promise<Document> {
        const document = Object.assign({}, _document);
        if (document.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putDocuments.replace('$(documentId)', document.id!),
                requiresToken: false,
                payload: document,
            }
            return HttpClient.put<Document>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postDocuments.replace('$(documentId)', document.id!),
                requiresToken: false,
                payload: document,
            }
            return HttpClient.post<Document>(getParameters)
        }
    }

    deleteDocument(document: Document): Promise<Document> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteEnvironnement.replace('$(documentId)', document.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Document>(getParameters)
    }

}
