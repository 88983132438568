export default class Image {
  id?: string;
  image?: string;
  comment?: string;

  constructor() {
    this.id = undefined;
    this.image = undefined;
    this.comment = "";
  }

}