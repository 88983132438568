import VisiteMenuiserie from '@/models/visites/VisisteMenuiserie'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Visite from '@/models/visites/Visite'

/**
* @Name VisiteMenuiseriesApiClientUrlsInterface
* @description
* Interface for the VisiteMenuiseries urls used to avoid hard-coded strings
*/
export interface VisiteMenuiseriesApiClientUrlsInterface {
    getVisiteMenuiseries: string
    getVisiteMenuiserie: string
    postVisiteMenuiserie: string
    putVisiteMenuiserie: string
    deleteVisiteMenuiserie: string

    copyFromVisite: string
    generatePDF: string
}

/**
* @Name VisiteMenuiseriesApiClientInterface
* @description
* Interface for the VisiteMenuiseries api client module
*/
export interface VisiteMenuiseriesApiClientInterface {
    getVisiteMenuiseries: (apiFilters?: any) => Promise<VisiteMenuiserie[]>
    getVisiteMenuiserie: (visiteMenuiserie: VisiteMenuiserie) => Promise<VisiteMenuiserie>
    sendVisiteMenuiserie: (visiteMenuiserie: VisiteMenuiserie) => Promise<VisiteMenuiserie>
    deleteVisiteMenuiserie: (visiteMenuiserie: VisiteMenuiserie) => Promise<VisiteMenuiserie>

    copyFromVisite: (visite: Visite) => Promise<VisiteMenuiserie>
    generatePDF: (visite: VisiteMenuiserie) => Promise<any>
}

/**
* @Name VisiteMenuiseriesApiClientModel
* @description
* Implements the VisiteMenuiseriesApiClientInterface interface
*/
export class VisiteMenuiseriesApiClientModel implements VisiteMenuiseriesApiClientInterface {
    private readonly urls!: VisiteMenuiseriesApiClientUrlsInterface
    constructor(urls: VisiteMenuiseriesApiClientUrlsInterface) {
        this.urls = urls
    }

    getVisiteMenuiseries(apiFilters?: any): Promise<VisiteMenuiserie[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisiteMenuiseries,
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<VisiteMenuiserie[]>(getParameters)
    }

    getVisiteMenuiserie(visiteMenuiserie: VisiteMenuiserie): Promise<VisiteMenuiserie> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisiteMenuiserie.replace('$(visiteMenuiserieId)', visiteMenuiserie.id),
            requiresToken: false,
        }
        return HttpClient.get<VisiteMenuiserie>(getParameters)
    }

    sendVisiteMenuiserie(visiteMenuiserie: VisiteMenuiserie): Promise<VisiteMenuiserie> {
        if (visiteMenuiserie.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putVisiteMenuiserie.replace('$(visiteMenuiserieId)', visiteMenuiserie.id),
                requiresToken: false,
                payload: visiteMenuiserie,
            }
            return HttpClient.put<VisiteMenuiserie>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postVisiteMenuiserie.replace('$(visiteMenuiserieId)', visiteMenuiserie.id),
                requiresToken: false,
                payload: visiteMenuiserie,
            }
            return HttpClient.post<VisiteMenuiserie>(getParameters)
        }
    }

    deleteVisiteMenuiserie(visiteMenuiserie: VisiteMenuiserie): Promise<VisiteMenuiserie> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteVisiteMenuiserie.replace('$(visiteMenuiserieId)', visiteMenuiserie.id),
            requiresToken: false,
        }
        return HttpClient.delete<VisiteMenuiserie>(getParameters)
    }

    copyFromVisite(visite: Visite): Promise<VisiteMenuiserie> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.copyFromVisite.replace('$(visiteId)', visite.id),
            requiresToken: false,
        }
        return HttpClient.post<VisiteMenuiserie>(getParameters)
    }

    generatePDF(visite: VisiteMenuiserie): Promise<any> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.generatePDF.replace('$(visiteId)', visite.id),
            requiresToken: false,
            responseType: 'blob'
        }
        return HttpClient.get<any>(getParameters)
    }
}
