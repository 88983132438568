export default class Piece {
  id: string;
  bien?: any;
  name: string;
  longueur?: number;
  largeur?: number;
  surface?: number;
  hauteur?: number;
  ventilation: string;
  images_ventilation: any[];
  climatisation: string;
  images_climatisation: any[];
  is_editable: boolean
  menuiseries: Array<any>
  chauffages: Array<any>
  index?: number

  constructor() {
    this.id = 'notSet';
    this.bien = undefined;
    this.name = '';
    this.surface = undefined;
    this.ventilation = 'absence_ventilation';
    this.climatisation = 'aucune';
    this.is_editable = false;
    this.menuiseries = [];
    this.chauffages = [];
    this.images_climatisation = []
    this.images_ventilation = []
  }
}