import Menuiserie from "../biens/Menuiserie";

export default class VisiteMenuiserie {
  id: string;
  nom?: string;
  prenom?: string;
  telephone?: string;
  email?: string; // Renommé depuis mail
  adresse?: string;
  code_postal?: string;
  ville?: string;
  realise_par?: any; 
  vpo?: any; 
  date_create?: any;
  date_update?: any;
  franchise?: any;
  menuiseries?: Menuiserie[];

  constructor() {
      this.id = 'notSet';
      this.nom = undefined;
      this.prenom = undefined;
      this.telephone = undefined;
      this.email = undefined;
      this.adresse = undefined;
      this.code_postal = undefined;
      this.ville = undefined;
      this.realise_par = undefined;
      this.vpo = undefined;
      this.franchise = undefined;
      this.menuiseries = []
  }

  get fullAdresse(): string {
      return `${this.adresse} ${this.code_postal} ${this.ville}`;
  }

  get creationDate(): string {
    const date = new Date(this.date_create!)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    
    return `${day}/${month}/${year}`;
  }

  get is_completed(): boolean {
    if(this.id !== 'notSet'){
      if(this.vpo && this.date_create === this.date_update){
        return false
      }
      return true
    }
    return false;
  }
}
