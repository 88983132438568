<template>
  <div id="camera">
    <input v-if="isFileSearcher" type="file" ref="fileInput" accept="image/*" @change="addImage" style="display: none" />
    <div class="button_container">
      <Button
          style="padding: 5px"
          class="p-col p-button-text p-button-rounded"
          :label="!isFileSearcher && showLabel ? 'Prendre une photo' : ''"
          icon="pi pi-camera"
          iconPos="right"
          @click="startCamera"
      />
      <input 
        hidden
        type="file"
        ref="imageInput"
        name="camera"
        accept="image/*"
        capture="environment"
        @change="addImage"/>
      <span v-if="isFileSearcher" class="p-m-2">/</span>
      <Button
          v-if="isFileSearcher"
          style="padding: 5px"
          class="p-col p-button-text p-button-rounded"
          :label="!isFileSearcher && showLabel ? 'Ajouter une image' : ''"
          icon="pi pi-folder"
          iconPos="right"
          @click="searchFile"
      />
    </div>
    <Sidebar v-model:visible="visible" position="full" @hide="image = undefined">
      <div class="container">
        <div>
          <!-- <img :src="photo" alt="Photo Preview" class="photo"/> -->
          <DrawPlayground ref="drawableImage" v-if="photo" :photo="photo" @sendPhoto="sendPhoto"></DrawPlayground>
          <div  class="comment-section">
            <TextArea class="p-fluid"  v-model="comment" placeholder="Ajoutez un commentaire" autoResize  />
            <div class="p-col">
              <Button @click="deleteImage" v-if="image" label="Supprimer" class="p-button-danger p-mr-2" icon="pi pi-trash" />
              <Button @click="drawableImage.saveCustomPhoto()" :loading="sendingImage" label="Enregistrer" icon="pi pi-save" />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps, defineExpose, onUnmounted } from 'vue';
import Sidebar from "primevue/sidebar"
import Button from "primevue/button"
import TextArea from "primevue/textarea"
import axios from 'axios';
import { useUsersStore } from '@/store/users';
import IImage from '@/models/images/Image'
import DrawPlayground from "@/components/app/DrawPlayground.vue"
import apiClient from '@/api-client';

const video = ref(null);
const canvas = ref(null);
const photo = ref(null);
const comment = ref('');
const visible = ref(false);
const drawableImage = ref(null)
// const cameraStarted = ref(false);
// let stream = null;
const usersStore = useUsersStore();
const baseUrl = process.env.VUE_APP_API_URL;

const imageInput = ref();
const image = ref()

const props = defineProps(["showLabel", 'isFileSearcher']);
const emit = defineEmits();
const sendingImage = ref(false)
// const currentFacingMode = ref('environment');
const fileInput = ref()

const handlePopState = (event) => {
  // Empêche le retour en arrière
  if(visible.value){
    event.preventDefault()
    window.history.pushState(null, '', window.location.href)
    visible.value = false
  }
  
}

onMounted(() => {
  // Ajoute une entrée dans l'historique
  window.history.pushState(null, '', window.location.href)
  // Écoute le bouton retour
  window.addEventListener('popstate', handlePopState)
  })

  onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState)
})

const addImage = async (file) => {
  const resizedFile = await handleFileChange(file, false)
  console.log("resized file : ", resizedFile)
  photo.value = URL.createObjectURL(resizedFile);
  visible.value = true
}

const startCamera = () => {
  imageInput.value.click()
};

const sendImage = (blob) => {
  const img = new File([blob], "image.png",{ type: "image/png" })
  const fd = new FormData();
  fd.append("image", img);
  fd.append("comment", comment.value);
  if(image.value){
    axios
    .put(process.env.VUE_APP_API_URL + "/api/images/" + image.value.id, fd, {
      headers: {
        Authorization: "Token " + usersStore.state.appUser.token,
      },
    })
    .then((res) => {
      const newImage = Object.assign(new IImage(), res.data);
      emit("addValue", newImage);
      photo.value = undefined;
      comment.value = '';
      visible.value = false;
      sendingImage.value = false
      image.value = undefined;
    });
  }else{
    axios
      .post(process.env.VUE_APP_API_URL + "/api/images", fd, {
        headers: {
          Authorization: "Token " + usersStore.state.appUser.token,
        },
      })
      .then((res) => {
        const newImage = Object.assign(new IImage(), res.data);
        emit("addValue", newImage);
        photo.value = undefined;
        comment.value = '';
        visible.value = false;
        sendingImage.value = false
      });
  }
}

const sendPhoto = (photoURL) => {
  sendingImage.value = true
  fetch(photoURL)
  .then(res => res.blob())
  .then(blob => {
    sendImage(blob)
  })
}

const loadImage = async (img) => {
  comment.value = img.comment ? img.comment : ""
  image.value = img
  try {
    // Faites une requête GET pour récupérer l'image en tant que Blob
    const response = await axios.get(baseUrl + img.image, {
      responseType: "blob", // Nécessaire pour recevoir les données en tant que Blob
    });

    // Créez un ObjectURL à partir du Blob
    photo.value = URL.createObjectURL(response.data);
    visible.value = true
  } catch (error) {
    console.error("Erreur lors du chargement de l'image :", error);
    throw error;
  }
};

// const loadImage = (img) => {
//   comment.value = img.comment ? img.comment : ""
//   image.value = img
//   fetch(baseUrl + img.image, {
//     method: "GET",
//     headers: {
//         "Content-Type": "application/json",
//     },
//   })
//     .then(response => {
//         if (!response.ok) {
//             throw new Error("Erreur lors du chargement de l'image");
//         }
//         return response.blob();
//     })
//     .then(blob => {
//       photo.value = URL.createObjectURL(blob);
//       visible.value = true
//     })
//     .catch(error => console.error("Erreur :", error));
// }

const deleteImage = () => {
  emit('removeImage', image.value)
  apiClient.images.deleteImage(image.value)
  image.value = undefined
  visible.value = false
}


const searchFile = () => {
  fileInput.value.click()
}

const handleFileChange = (event, is_sender) => {
  const file = event.target.files[0];

  if (!file) return;

  return new Promise((resolve, reject) => {
    const img = new Image();
    const imageUrl = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      console.log(img.width)
      console.log(img.height)

      // Définir la hauteur maximale de 550px et calculer la largeur proportionnelle
      // console.log(img.height)
      // const maxHeight = 400;
      // const scaleFactor = maxHeight / img.height;
      // const newWidth = img.width * scaleFactor;
      // const newHeight = maxHeight;
      // console.log(newWidth, newHeight)

      // Configurer la taille du canvas
      canvas.width = img.width;
      canvas.height = img.height;

      // Dessiner l'image redimensionnée sur le canvas
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convertir le canvas en Blob (image)
      canvas.toBlob(
        (blob) => {
          if (is_sender) {
            sendImage(blob); // Appeler la méthode d'envoi
            resolve(); // Terminer la promesse
          } else {
            resolve(blob); // Résoudre avec le Blob
          }
          URL.revokeObjectURL(imageUrl); // Libérer l'URL
        },
        file.type
      );
    };

    img.onerror = (err) => {
      URL.revokeObjectURL(imageUrl); // Libérer l'URL en cas d'erreur
      reject(err); // Rejeter en cas d'erreur
    };

    img.src = imageUrl; // Charger l'image
  });
};

defineExpose({
  loadImage
})

</script>

<style lang="scss">

  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    position: relative;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .centered-button {
    margin: auto;
    margin-top: -50px;
  }

  .photo {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: -50px;
  }

  .comment-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  textarea {
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
  }

  .button_container {
    display: flex;
    align-items: center;
}

</style>