export default class Menuiserie {
  id: string | null;
  type_reno?: string;
  couleur?: string;
  description?: string;
  visite_menuiseries?: string
  piece?: string;
  type: string;
  matiere: string;
  nature_vitrage: string;
  type_isolation_vitrage?: string;
  occultation: string;
  hauteur?: number;
  largeur?: number;
  epaisseur?: number;
  images_menuiserie: any[];
  is_loading_price: boolean;
  price?: number

  constructor() {
    this.id = null;
    this.piece = undefined;
    this.type = '';
    this.matiere = '';
    this.nature_vitrage = '';
    this.type_isolation_vitrage = undefined;
    this.occultation = '';
    this.hauteur = undefined;
    this.largeur = undefined;
    this.epaisseur = undefined;
    this.images_menuiserie = []
    this.is_loading_price = false;
  }
}