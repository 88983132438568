import ECS from "./ECS";
import Face from "./Face";
import Plancher from "./Plancher";
import Piece from "./Piece";
import SystemeChauffage from "./SystemeChauffage";
import Toiture from "./Toiture";

export default class Bien {
  id: string;
  visite: any;
  susceptibilite: string;
  logement: string;
  mitoyennete: string;
  nature_batiment: string;
  niveaux_chauffes: string;
  presence_veranda?: boolean;
  piscine_chauffee?: boolean;
  systeme_recup_eau?: boolean;
  cloture_securisee?: string;
  protection_chute?: boolean;
  images_protections: any[];
  etat_garde_corde: string;
  type_mur: string;
  epaisseur_murs: number;
  etat_mur: string;
  images_etat_mur: any[];
  type_isolation: string;
  images_type_isolation: any[];
  nature_isolation: string | null;
  etat_isolation: string;
  porte_materiel: string;
  type_porte: string;
  specificites_porte: string;
  images_porte: any[];
  is_porte_securised: boolean;
  images_serrure: any[];
  nombre_points_surete?: number;
  securite_porte: string;
  type_serrure: string;
  certifie_a2p: string;
  etat_porte: string;
  porte_service_materiel: string | null;
  type_porte_service: string | null;
  specificites_porte_service: string | null;
  images_porte_service: any[];
  etat_porte_service: string | null;
  is_garde_corps: boolean;
  images_garde_corps: any[];
  etat_garde_corps: string;
  fenetre_rdc_protegee: string | null;
  images_barreaudage_fenetre: any[];
  porte_maison_garage: string | null;
  images_porte_maison_garage: any[];
  is_porte_maison_garage_securised: boolean | null;
  images_porte_maison_garage_securised: any[];

  type_plancher: string;
  type_isolation_plancher: string;
  presence_isolant: string;
  etat_isolant: string;
  is_presence_humidite: boolean;
  type_toiture: string;
  images_toiture: any[];
  etat_toiture: string;
  if_toiture_terrasse: string;
  images_toiture_terrasse: any[];
  presence_conduit_fumee: string;
  images_conduit_fumee: any[];
  evac_eau_pluviale: string;
  images_evac_eau_pluviale: any[];
  etat_evac: string;
  type_charpente: string;
  etat_charpente: string;
  isolation_comble: string;
  images_isolation_comble: any[];
  etat_isolation_comble: string;
  presence_fenetre_toit: string;
  images_fenetres_toit: any[];
  is_presence_trappe_comble: boolean;
  dimension_trappe: string | null;
  lieu_trappe: string | null;
  images_trappe_comble: any[];
  is_isolation_trappe: boolean;
  structure_plafond: string;
  images_plafond: any[];
  is_plafond_isole: boolean;
  presence_spots: string;
  images_spots: any[];
  etat_plafond: string;
  presence_insert: string;
  images_insert: any[];
  etat_conduit: string | null;
  systeme_chauffage: string;
  images_systeme_chauffage: any[];
  reference_systeme: string;
  images_reference_systeme: any[];
  type_pac: string | null;
  type_gaz: string | null;
  type_fioul: string | null;
  type_bois: string | null;
  type_charbon: string | null;
  is_presence_programmateur: boolean;
  images_programmateur: any[];
  is_presence_regulateur: boolean;
  images_regulateur: any[];
  etat_chauffage: string;
  autre_chauffage: string;
  images_autre_chauffage: any[];
  eau_chaude: string;
  images_eau_chaude: any[];
  type_electricite: string | null;
  type_ce_electrique: string | null;
  type_eau_gaz: string | null;
  type_chaudiere: string | null;
  type_eau_fioul: string | null;
  type_eau_bois: string | null;
  reference_eau: string | null;
  presence_veilleuse: boolean | null;
  is_presence_vanne_arret: boolean;
  images_vanne_arret: any[];
  is_presence_point_solaire: boolean;
  type_appoint_solaire: string;
  is_presence_calorifugeage: boolean;
  classe_isolation: string | null;
  etat_eau_chaude: string;
  autre_systeme_eau_chaude: string;
  images_autre_systeme_eau_chaude: any[];
  is_autre_presence_calorifugeage: boolean | null;
  autre_classe_isolation: string | null;
  type_ventillation: string;
  images_ventillation: any[];
  reference_ventillation: string;
  nombre_de_bouche?: number
  images_bouche_ventilation: any[];
  etat_ventillation: string;
  type_climatisation: string;
  images_climatisation: any[];
  reference_climatisation: string | null;
  surface_climatisee: number | null;
  etat_climatisation: string | null;
  nature_alimentation: string;
  is_presence_commande?: boolean;
  images_commande: any[];
  is_presence_prise_terre?: boolean;
  images_prise_terre: any[];
  is_installation_electrique?: boolean;
  images_installation_electrique: any[];
  is_presence_disjoncteur?: boolean;
  images_disjoncteur: any[];
  is_absence_materiel_inadapte?: boolean;
  images_materiel_inadapte: any[];
  is_conducteur_isole?: boolean;
  images_conducteur_isole: any[];
  etat_electricite: string;
  is_presence_capteur_pv?: boolean;
  images_pv: any[];
  surface_pv: number | null;
  modele_pv: string | null;
  reference_pv: string | null;
  etiquetage_pv: boolean;
  images_etiquetage_pv: any[];

  faces: Array<any>
  pieces: Array<any>
  planchers: Array<any>
  toitures: Array<any>
  systemes_chauffage: Array<any>
  ecs: Array<any>

  commentaire_general?: string

  constructor() {
    this.id = 'notSet';
    this.visite = undefined;
    this.susceptibilite = '';
    this.logement = '';
    this.mitoyennete = '';
    this.nature_batiment = '';
    this.niveaux_chauffes = '';
    this.presence_veranda = undefined;
    this.piscine_chauffee = undefined;
    this.systeme_recup_eau = undefined;
    this.cloture_securisee = undefined;
    this.protection_chute = undefined;
    this.images_protections = [];
    this.etat_garde_corde = '';
    this.type_mur = '';
    this.epaisseur_murs = 0;
    this.etat_mur = '';
    this.images_etat_mur = [];
    this.type_isolation = '';
    this.images_type_isolation = []
    this.nature_isolation = null;
    this.etat_isolation = '';
    this.porte_materiel = '';
    this.type_porte = '';
    this.specificites_porte = '';
    this.images_porte = [];
    this.is_porte_securised = false;
    this.images_serrure = [];
    this.nombre_points_surete = undefined;
    this.securite_porte = '';
    this.type_serrure = '';
    this.certifie_a2p = '';
    this.etat_porte = '';
    this.porte_service_materiel = null;
    this.type_porte_service = null;
    this.specificites_porte_service = null;
    this.images_porte_service = [];
    this.etat_porte_service = null;
    this.is_garde_corps = false;
    this.images_garde_corps = [];
    this.etat_garde_corps = '';
    this.fenetre_rdc_protegee = null;
    this.images_barreaudage_fenetre = []
    this.porte_maison_garage = null;
    this.images_porte_maison_garage = []
    this.is_porte_maison_garage_securised = null;
    this.images_porte_maison_garage_securised = []
    this.type_plancher = '';
    this.type_isolation_plancher = '';
    this.presence_isolant = '';
    this.etat_isolant = '';
    this.is_presence_humidite = false;
    this.type_toiture = '';
    this.etat_toiture = ""
    this.images_toiture = [];
    this.if_toiture_terrasse = '';
    this.images_toiture_terrasse = [];
    this.presence_conduit_fumee = '';
    this.images_conduit_fumee = [];
    this.evac_eau_pluviale = '';
    this.images_evac_eau_pluviale = [];
    this.etat_evac = '';
    this.type_charpente = '';
    this.etat_charpente = '';
    this.isolation_comble = '';
    this.images_isolation_comble = [];
    this.etat_isolation_comble = '';
    this.presence_fenetre_toit = '';
    this.images_fenetres_toit = [];
    this.is_presence_trappe_comble = false;
    this.dimension_trappe = null;
    this.lieu_trappe = null;
    this.images_trappe_comble = [];
    this.is_isolation_trappe = false;
    this.structure_plafond = '';
    this.images_plafond = [];
    this.is_plafond_isole = false;
    this.presence_spots = '';
    this.images_spots = []
    this.etat_plafond = '';
    this.presence_insert = '';
    this.images_insert = [];
    this.etat_conduit = null;
    this.systeme_chauffage = '';
    this.images_systeme_chauffage = [];
    this.reference_systeme = '';
    this.images_reference_systeme = [];
    this.type_pac = null;
    this.type_gaz = null;
    this.type_fioul = null;
    this.type_bois = null;
    this.type_charbon = null;
    this.is_presence_programmateur = false;
    this.images_programmateur = [];
    this.is_presence_regulateur = false;
    this.images_regulateur = [];
    this.etat_chauffage = '';
    this.autre_chauffage = '';
    this.images_autre_chauffage = [];
    this.eau_chaude = '';
    this.images_eau_chaude = [];
    this.type_electricite = null;
    this.type_eau_gaz = null;
    this.type_eau_fioul = null;
    this.type_eau_bois = null;
    this.type_ce_electrique = null;
    this.type_chaudiere = null;
    this.reference_eau = null;
    this.presence_veilleuse = null;
    this.is_presence_vanne_arret = false;
    this.images_vanne_arret = [];
    this.is_presence_point_solaire = false;
    this.type_appoint_solaire = '';
    this.is_presence_calorifugeage = false;
    this.classe_isolation = null;
    this.etat_eau_chaude = '';
    this.autre_systeme_eau_chaude = '';
    this.images_autre_systeme_eau_chaude = [];
    this.is_autre_presence_calorifugeage = null;
    this.autre_classe_isolation = null;
    this.type_ventillation = '';
    this.images_ventillation = [];
    this.images_bouche_ventilation = [];
    this.reference_ventillation = '';
    this.etat_ventillation = '';
    this.type_climatisation = '';
    this.images_climatisation = [];
    this.reference_climatisation = null;
    this.surface_climatisee = null;
    this.etat_climatisation = null;
    this.nature_alimentation = '';
    this.is_presence_commande = undefined;
    this.images_commande = [];
    this.is_presence_prise_terre = undefined;
    this.images_prise_terre = [];
    this.is_installation_electrique = undefined;
    this.images_installation_electrique = [];
    this.is_presence_disjoncteur = undefined;
    this.images_disjoncteur = [];
    this.is_absence_materiel_inadapte = undefined;
    this.images_materiel_inadapte = [];
    this.is_conducteur_isole = undefined;
    this.images_conducteur_isole = [];
    this.etat_electricite = '';
    this.is_presence_capteur_pv = undefined;
    this.images_pv = [];
    this.surface_pv = null;
    this.modele_pv = null;
    this.reference_pv = null;
    this.etiquetage_pv = false;
    this.images_etiquetage_pv = [];
    this.faces = [new Face(), new Face(), new Face(), new Face()]
    this.pieces = [new Piece(), new Piece(), new Piece()]
    this.planchers = [new Plancher()]
    this.toitures = [new Toiture()]
    this.systemes_chauffage = [new SystemeChauffage()]
    this.ecs = [new ECS()]
    this.commentaire_general
  }
}
