import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
// import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import StartPage from '../views/StartPage.vue'
import Forbidden403Page from '../views/Forbidden403Page.vue'
import ResetPasswordPage from '../views/ResetPasswordPage.vue'
import CollaborateursPage from '../views/dashboard/CollaborateursPage.vue'
import CollaborateurDetailPage from '../views/dashboard/CollaborateurDetailPage.vue'
import UtilisateursPage from '../views/dashboard/UtilisateursPage.vue'
import UtilisateurDetailPage from '../views/dashboard/UtilisateurDetailPage.vue'
import AuditsPage from '../views/dashboard/AuditsPage.vue'
import AuditDetailPage from '../views/dashboard/AuditDetailPage.vue'
import SimulationUtilisateursPage from '../views/SimulationUtilisateursPage.vue'

import VisitesPage from '../views/dashboard/VisitesPage.vue'
import VisiteDetailPage from '../views/dashboard/VisiteDetailPage.vue'
import FranchisesPage from '@/views/dashboard/FranchisesPage.vue';
import FranchiseDetailPage from '@/views/dashboard/FranchiseDetailPage.vue';
import VisiteSousPlanchersPage from '../views/dashboard/VisiteSousPlanchersPage.vue'
import VisiteSousPlancherDetailPage from '../views/dashboard/VisiteSousPlancherDetailPage.vue'
import VisiteCertiRenovDetailPage from '@/views/dashboard/VisiteCertiRenovDetailPage.vue';
import VisitesCertiRenovPage from '@/views/dashboard/VisitesCertiRenovPage.vue';
import VisiteMenuiserieDetailPage from '@/views/dashboard/VisiteMenuiserieDetailPage.vue';
import VisiteMenuiseriesPage from '@/views/dashboard/VisiteMenuiseriesPage.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/visites_certirenov',
  },
  {
    path: '/collaborateur/:collaborateurId',
    name: 'CollaborateurDetailPage',
    component: CollaborateurDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'contacts.view_collaborateur',
    }
  },
  {
    path: '/collaborateurs',
    name: 'CollaborateursPage',
    component: CollaborateursPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'contacts.view_collaborateur',
    }
  },
  {
    path: '/utilisateurs',
    name: 'UtilisateursPage',
    component: UtilisateursPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
    }
  },
  {
    path: '/utilisateur/:utilisateurId',
    name: 'UtilisateurDetailPage',
    component: UtilisateurDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_user',
    }
  },
  {
    path: '/visites/:visiteId',
    name: 'VisiteDetailPage',
    component: VisiteDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visite',
    }
  },
  {
    path: '/visites',
    name: 'VisitesPage',
    component: VisitesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visite',
    }
  },
  {
    path: '/visites_certirenov/:visiteId',
    name: 'VisiteCertiRenovDetailPage',
    component: VisiteCertiRenovDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visite',
    }
  },
  {
    path: '/visites_certirenov',
    name: 'VisitesCertiRenovPage',
    component: VisitesCertiRenovPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visite',
    }
  },
  {
    path: '/franchises/:franchiseId',
    name: 'FranchiseDetailPage',
    component: FranchiseDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'contacts.view_franchise',
    }
  },
  {
    path: '/franchises',
    name: 'FranchisesPage',
    component: FranchisesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'contacts.view_franchise',
    }
  },
  {
    path: '/visite_sous_planchers/:visiteSousPlancherId',
    name: 'VisiteSousPlancherDetailPage',
    component: VisiteSousPlancherDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visitesousplancher',
    }
  },
  {
    path: '/visite_sous_planchers',
    name: 'VisiteSousPlanchersPage',
    component: VisiteSousPlanchersPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visitesousplancher',
  
    }
  },
  {
    path: '/visite_menuiseries/:visiteMenuiserieId',
    name: 'VisiteMenuiserieDetailPage',
    component: VisiteMenuiserieDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visitemenuiserie',
    }
  },
  {
    path: '/visite_menuiseries',
    name: 'VisiteMenuiseriesPage',
    component: VisiteMenuiseriesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'visites.view_visitemenuiserie',
  
    }
  },
  // Common
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      layout: 'PageLayout'
    }  },
  {
    path: '/simulation-utilisateur/:userId',
    name: 'SimulationUtilisateur',
    component: SimulationUtilisateursPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/start/:id',
    name: 'Start',
    component: StartPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/forbidden403',
    name: 'Forbidden403',
    component: Forbidden403Page,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/resetpassword/:id',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  // Autre
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/audits/:table:/:id',
    alias: ['/audits'],
    name: 'AuditsPage',
    component: AuditsPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_audit',
      breadcrumbItems: [
        { label: 'navigation.thinker.home', to: '/' },
        { label: 'navigation.thinker.auditListe', to: '/' }
      ]
    }
  },
  {
    path: '/audit/:id',
    name: 'AuditDetailPage',
    component: AuditDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'users.view_audit',
      breadcrumbItems: [
        { label: 'navigation.thinker.home', to: '/' },
        { label: 'navigation.thinker.auditDetail', to: '/' }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router