import {
    VisitesApiClientUrlsInterface,
    VisitesApiClientInterface,
    VisitesApiClientModel
} from '@/models/api-client/visites/VisitesApiClient'

const urls: VisitesApiClientUrlsInterface = {
    getVisites: process.env.VUE_APP_API_URL + '/api/visites',
    getVisite: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)',
    postVisite: process.env.VUE_APP_API_URL + '/api/visites',
    putVisite: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)',
    deleteVisite: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)',
    transformToNF: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)/transform_to_nf',

    generatePDF: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)/generate_pdf',
    generateCertiRenovPDF: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)/generate_certi_pdf',
    getImages: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)/export_images',
    getFiles: process.env.VUE_APP_API_URL + '/api/visites/$(visiteId)/export_files',

    getEnvironnements: process.env.VUE_APP_API_URL + '/api/environnements',
    getEnvironnement: process.env.VUE_APP_API_URL + '/api/environnements/$(environnementId)',
    postEnvironnement: process.env.VUE_APP_API_URL + '/api/environnements',
    putEnvironnement: process.env.VUE_APP_API_URL + '/api/environnements/$(environnementId)',
    deleteEnvironnement: process.env.VUE_APP_API_URL + '/api/environnements/$(environnementId)',

    getDocuments: process.env.VUE_APP_API_URL + '/api/documents',
    getDocument: process.env.VUE_APP_API_URL + '/api/documents/$(documentId)',
    postDocuments: process.env.VUE_APP_API_URL + '/api/documents',
    putDocuments: process.env.VUE_APP_API_URL + '/api/documents/$(documentId)',
    deleteDocuments: process.env.VUE_APP_API_URL + '/api/documents/$(documentId)'
}

// instantiate the VisitesApiClient pointing at the url that returns static json mock \data
const visitesApiClient: VisitesApiClientInterface = new VisitesApiClientModel(urls)

// export our instance
export default visitesApiClient
