import axios, {
    AxiosRequestConfig,
    AxiosResponse
} from 'axios'

import { HttpRequestParamsInterface } from './HttpRequestParams.interface'
import { HttpClientInterface } from './HttpClient.interface'
import { useUsersStore } from "@/store/users";
import { useErrorStore } from "@/store/error";
import { MutationType } from "@/models/store";
import { useLoadingStore } from '@/store/loading'
const loadingStore = useLoadingStore() 


/**
* @name HttpClientModel
* @description
* Wraps http client functionality to avoid directly using a third party npm package\
like axios
* and simplify replacement in the future if such npm package would stop being devel\
oped or other reasons
*/
export class HttpClientModel implements HttpClientInterface {

    constructor() {
        // OPTIONAL for now: Add request interceptor to handle errors or other things for each request in one place
        function isIsoDateString(value: any): boolean {
            return value instanceof Date;
        }

        function handleDates(body: any) {
            if (body === null || body === undefined || typeof body !== "object")
                return body;

            for (const key of Object.keys(body)) {
                const value = body[key];
                if (isIsoDateString(value)) {
                    body[key] = Math.trunc(value.getTime() / 1000);
                } else if(key.startsWith('date_') &&  value == '') {
                    body[key] = null;
                }
                else if(typeof value === "object") {
                    handleDates(value);
                }
            }
        }

        function handleBlank(body: any) {
            if (body === null || body === undefined || typeof body !== "object")
                return body;

            for (const key of Object.keys(body)) {
                const value = body[key];
                if (value === '') {
                    body[key] = null;
                }
                else if(typeof value === "object") {
                    handleBlank(value);
                }
            }
        }

        function changeTimestampsToDate(body: any) {
            if (body === null || body === undefined || typeof body !== "object")
                return body;
            
            for (const key of Object.keys(body)) {
                const value = body[key];
                if (key.startsWith('date_') &&  value != null && key != 'date_create' && key != 'date_update') {
                    const date = new Date(parseInt(value) * 1000);
                    body[key] =  date;
                }
                else if (typeof value === "object") changeTimestampsToDate(value);
            }
        }

        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            // Do something before request is sent*
            handleBlank(config.data)
            handleDates(config.data);
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            changeTimestampsToDate(response.data)
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }

    private getToken(): string {
        const usersStore = useUsersStore()
        if (!usersStore.state.appUser.token && localStorage.getItem('appUser')) {
            usersStore.action(MutationType.users.loadFromLocalstorageAppUser, {
                appUser: JSON.parse(localStorage.getItem('appUser')!)
            });
        }
        return usersStore.state.appUser.token
    }

    get<T>(parameters: HttpRequestParamsInterface): Promise<T> {
        const errorStore = useErrorStore()
        return new Promise<T>((resolve, reject) => {
            const { url, filters, onDownloadProgress, responseType } = parameters
            // axios options
            const token = this.getToken()
            const options: AxiosRequestConfig = {
                headers: { Authorization: "Token " + token },
                params: filters ? filters : [],
                responseType: responseType
            }
            if (onDownloadProgress) {
                loadingStore.action(MutationType.loading.setLoad);
                options.onDownloadProgress = (progressEvent) => {
                    const progressValue = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    loadingStore.action(MutationType.loading.loadProgress, progressValue);
                };
            }
            axios
                .get(url, options)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((response: AxiosResponse) => {
                    errorStore.action(MutationType.error.writeError, {
                        error: {
                            request_status: response.request.status,
                            request_statusText: response.request.statusText,
                        }
                    });
                    reject(response)
                })
        })
    }

    post<T>(parameters: HttpRequestParamsInterface): Promise<T> {
        const usersStore = useUsersStore()
        const errorStore = useErrorStore()
        return new Promise<T>((resolve, reject) => {
            const { url, payload, filters } = parameters
            // axios options
            const token = this.getToken()
            const options: AxiosRequestConfig = {
                headers: { Authorization: "Token " + token },
                params: filters ? filters : []
            }
            // UrlQuery si simulation User (...url?real_user=azertyuioplkjhgfds)
            if (usersStore.state.appUser.real_user) {
                options.params['real_user'] = usersStore.state.appUser.real_user
            }
            axios
                .post(url, payload, options)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((response: AxiosResponse) => {
                    errorStore.action(MutationType.error.writeError, {
                        error: {
                            request_status: response.request.status,
                            request_statusText: response.request.statusText,
                        }
                    });
                    reject(response)
                })
        })
    }

    put<T>(parameters: HttpRequestParamsInterface): Promise<T> {
        const usersStore = useUsersStore()
        const errorStore = useErrorStore()
        return new Promise<T>((resolve, reject) => {
            const { url, payload, filters } = parameters
            // axios options
            const token = this.getToken()
            const options: AxiosRequestConfig = {
                headers: { Authorization: "Token " + token },
                params: filters ? filters : []
            }
            // UrlQuery si simulation User (...url?real_user=azertyuioplkjhgfds)
            if (usersStore.state.appUser.real_user) {
                options.params['real_user'] = usersStore.state.appUser.real_user
            }
            axios
                .put(url, payload, options)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((response: AxiosResponse) => {
                    errorStore.action(MutationType.error.writeError, {
                        error: {
                            request_status: response.request.status,
                            request_statusText: response.request.statusText,
                        }
                    });
                    reject(response)
                })
        })
    }

    delete<T>(parameters: HttpRequestParamsInterface): Promise<T> {
        const usersStore = useUsersStore()
        const errorStore = useErrorStore()
        return new Promise<T>((resolve, reject) => {
            const { url } = parameters
            // axios options
            const token = this.getToken()
            const options: AxiosRequestConfig = {
                headers: { Authorization: "Token " + token },
                params: {}
            }
            // UrlQuery si simulation User (...url?real_user=azertyuioplkjhgfds)
            if (usersStore.state.appUser.real_user) {
                options.params['real_user'] = usersStore.state.appUser.real_user
            }
            axios
                .delete(url, options)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((response: AxiosResponse) => {
                    errorStore.action(MutationType.error.writeError, {
                        error: {
                            request_status: response.request.status,
                            request_statusText: response.request.statusText,
                        }
                    });
                    reject(response)
                })
        })
    }
}