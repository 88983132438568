import {
    VisiteMenuiseriesApiClientUrlsInterface,
    VisiteMenuiseriesApiClientInterface,
    VisiteMenuiseriesApiClientModel
} from '@/models/api-client/visites_menuiseries/VisiteMenuiseriesApiClient'

const urls: VisiteMenuiseriesApiClientUrlsInterface = {
    getVisiteMenuiseries: process.env.VUE_APP_API_URL + '/api/visites_menuiserie',
    getVisiteMenuiserie: process.env.VUE_APP_API_URL + '/api/visites_menuiserie/$(visiteMenuiserieId)',
    postVisiteMenuiserie: process.env.VUE_APP_API_URL + '/api/visites_menuiserie',
    putVisiteMenuiserie: process.env.VUE_APP_API_URL + '/api/visites_menuiserie/$(visiteMenuiserieId)',
    deleteVisiteMenuiserie: process.env.VUE_APP_API_URL + '/api/visites_menuiserie/$(visiteMenuiserieId)',

    copyFromVisite: process.env.VUE_APP_API_URL + '/api/visites_menuiserie/copy/$(visiteId)',
    generatePDF: process.env.VUE_APP_API_URL + '/api/visites_menuiserie/$(visiteId)/generate_pdf',
}

// instantiate the VisiteMenuiseriesApiClient pointing at the url that returns static json mock \data
const visiteMenuiseriesApiClient: VisiteMenuiseriesApiClientInterface = new VisiteMenuiseriesApiClientModel(urls)

// export our instance
export default visiteMenuiseriesApiClient
