<template>
  <div class="visite-card">
    <Dialog v-model:visible="visible" modal header="Informations client" :style="{ width: '20rem' }">
      <div class="p-grid" style="align-items: center;">
        <i class="pi pi-user p-mr-3"></i>
        <b style="font-size: 20px;">{{ visite.prenom + " " + visite.nom.toUpperCase() }}</b>
      </div>
      <Button class="p-button-text" style="padding-left: 10px" @click="callCustomer">
        <i class="pi pi-phone p-mr-3"></i>
        <b>{{ visite.telephone}}</b>
      </Button>
      <Button class="p-button-text" style="padding-left: 10px" @click="emailCustomer">
        <i class="pi pi-at p-mr-3"></i>
        <b>{{ visite.email ? visite.email : visite.mail}}</b>
      </Button>
      <Button class="p-button-text" style="padding-left: 10px; text-align: left;" @click="openMap">
        <i class="pi pi-map p-mr-3"></i>
        <b>{{ visite.fullAdresse}}</b>
      </Button>
    </Dialog>
    <Button class="p-button p-button-outlined p-fluid" style="width: auto;" @click="setCustomer()" >
      <i class="p-mr-2 pi pi-user"></i>
      <span>{{ visiteData.prenom + " " }} <b>{{ visiteData.nom }}</b></span>
    </Button>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button';
import VisiteMenuiserie from '@/models/visites/VisisteMenuiserie';

const visite = ref(new VisiteMenuiserie())
const visible = ref(false)
const props = defineProps(["visiteData"])

const show = () => {
  visible.value = true
}

const hide = () => {
  visible.value = false
}

const setCustomer = () => {
  const dataSansGetter = { ...props.visiteData };
  delete dataSansGetter.is_completed;

  visite.value = Object.assign(new VisiteMenuiserie(), dataSansGetter)
  show()
}

const callCustomer = () => {
  if (visite.value.telephone) {
    window.location.href = `tel:${visite.value.telephone}`;
  } else {
    console.warn("Numéro de téléphone non disponible");
  }
};

const emailCustomer = () => {
  if (visite.value.email || visite.value.mail) {
    window.location.href = `mailto:${visite.value.email ? visite.value.email : visite.value.mail}`;
  } else {
    console.warn("Adresse email non disponible");
  }
};

const openMap = () => {
  const address = encodeURIComponent(visite.value.fullAdresse);
  const url = `https://www.google.com/maps/search/?api=1&query=${address}`;
  window.open(url, "_blank");
}


</script>

<style scoped lang="scss">
.visite-card{

}
</style>
